import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Button = makeShortcode("Button");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-a-collection-of-delightful-links",
      "style": {
        "position": "relative"
      }
    }}>{`⚾ A Collection of Delightful Links`}<a parentName="h1" {...{
        "href": "#-a-collection-of-delightful-links",
        "aria-label": " a collection of delightful links permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`A curated selection of resources and projects shared by the Kernel Gaming Guild 🙏`}</p>
    <h2 {...{
      "id": "gaming-guild-projects",
      "style": {
        "position": "relative"
      }
    }}>{`Gaming Guild Projects`}<a parentName="h2" {...{
        "href": "#gaming-guild-projects",
        "aria-label": "gaming guild projects permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`game3.js`}</strong></p>
          <p>{`A Javascript Game Framework. Contains `}<a parentName="p" {...{
              "href": "https://www.game3js.com"
            }}>{`open-source tools`}</a>{` and examples to enable developers to easily make Web 3 Games.`}</p>
          <Button to="https://game3js.substack.com/" mdxType="Button">
    Follow
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Genie`}</strong></p>
          <p>{`A platform for no-loss challenges, integrated with popular online games, which enables community-driven tournaments and challenges.`}</p>
          <Button to="https://app.thegenie.xyz" mdxType="Button">
    Play
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Decade`}</strong></p>
          <p>{`An Ethereum-based competitive multiplayer gaming platform where players can compete against one another in games like Chess, Battleships, and many more.`}</p>
          <Button to="https://decade.substack.com/" mdxType="Button">
    Read
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Spirit Creatures`}</strong></p>
          <p>{`Spirit Creatures are digital collectibles which are linked directly to real life animals. When you purchase a collectible, you enter the world of both the digital Spirit Creature, and its real world counterpart. This is your bond.`}</p>
          <Button to="https://www.spiritcreatures.io/" mdxType="Button">
    Explore
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Battle Racers`}</strong></p>
          <p>{`An action-packed arcade game where you build, race, and battle model cars on arcade-sized tracks. Your race car is a a non-fungible token that’s permanently tracked on the blockchain.`}</p>
          <Button to="https://battleracers.io" mdxType="Button">
    Play
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Wildcards`}</strong></p>
          <p>{`Always for sale ethereum based conservation tokens.`}</p>
          <Button to="https://wildcards.world/" mdxType="Button">
    Collect
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Nifty Ink`}</strong></p>
          <p>{`Nifty Ink is a decentralized application to create and trade artwork using Ethereum, IPFS, meta transactions, and side-chains for `}<em parentName="p">{`instant onboarding`}</em>{`.`}</p>
          <Button to="https://github.com/austintgriffith/scaffold-eth/tree/nifty-ink-dev/" mdxType="Button">
    Explore
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h3 {...{
      "id": "week-1-links",
      "style": {
        "position": "relative"
      }
    }}>{`Week 1 Links`}<a parentName="h3" {...{
        "href": "#week-1-links",
        "aria-label": "week 1 links permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <List mdxType="List">
      <Link to="https://medium.com/swlh/re-fungible-tokens-in-collectible-card-games-73a61703226f" mdxType="Link">
        <p>{`Re-Fungible Tokens, H/T Wade Kimbrough`}</p>
      </Link>
      <Link to="https://ecorner.stanford.edu/in-brief/the-knowledge-economy-of-world-of-warcraft/" mdxType="Link">
        <p>{`Coordination in WoW, H/T James Young`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=BoYyP7J5Sh0" mdxType="Link">
        <p>{`Digital Art on Blockchain, H/T Mark Muskardin`}</p>
      </Link>
    </List>
    <h3 {...{
      "id": "week-2-links",
      "style": {
        "position": "relative"
      }
    }}>{`Week 2 Links`}<a parentName="h3" {...{
        "href": "#week-2-links",
        "aria-label": "week 2 links permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <List mdxType="List">
      <Link to="https://blog.chain.link/44-ways-to-enhance-your-smart-contract-with-chainlink/" mdxType="Link">
        <p>{`44 Ways To Enhance Blockchain Smart Contracts With Chainlink Oracles, H/T Mike Ryan`}</p>
      </Link>
    </List>
    <h3 {...{
      "id": "week-3-links",
      "style": {
        "position": "relative"
      }
    }}>{`Week 3 Links`}<a parentName="h3" {...{
        "href": "#week-3-links",
        "aria-label": "week 3 links permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <List mdxType="List">
      <Link to="https://asherv.com/threes/threemails/" mdxType="Link">
        <p>{`Threes: The Rip-offs and Making Our Original Game`}</p>
      </Link>
      <Link to="https://www.fehrsam.xyz/blog/creators-communities-crypto" mdxType="Link">
        <p>{`Creators, Communities, and Crypto, H/T James Young`}</p>
      </Link>
      <Link to="https://tonysheng.substack.com/p/yfi-ponzinomics" mdxType="Link">
        <p>{`YFI Ponzinomics`}</p>
      </Link>
      <Link to="https://medium.com/@simondlr/what-is-harberger-tax-where-does-the-blockchain-fit-in-1329046922c6" mdxType="Link">
        <p>{`What is Harberger Tax & Where Does The Blockchain Fit In?, H/T Nichanan Kesonpat`}</p>
      </Link>
    </List>
    <h3 {...{
      "id": "week-4-links",
      "style": {
        "position": "relative"
      }
    }}>{`Week 4 Links`}<a parentName="h3" {...{
        "href": "#week-4-links",
        "aria-label": "week 4 links permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <List mdxType="List">
      <Link to="https://www.youtube.com/watch?v=bHM1DRHSUPw" mdxType="Link">
        <p>{`Community Inclusion Currencies, H/T Wade Kimbrough`}</p>
      </Link>
    </List>
    <h3 {...{
      "id": "week-5-links",
      "style": {
        "position": "relative"
      }
    }}>{`Week 5 Links`}<a parentName="h3" {...{
        "href": "#week-5-links",
        "aria-label": "week 5 links permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <List mdxType="List">
      <Link to="https://blog.zkga.me/announcing-darkforest" mdxType="Link">
        <p>{`Dark Forest - A Decentralized zk-RTS Game, H/T Andy Tudhope`}</p>
      </Link>
      <Link to="https://feedvitalik.com/" mdxType="Link">
        <p>{`Feed Vitalik - An Ethereum Transaction Visualizer, H/T Andy Tudhope`}</p>
      </Link>
    </List>
    <h3 {...{
      "id": "week-6-links",
      "style": {
        "position": "relative"
      }
    }}>{`Week 6 Links`}<a parentName="h3" {...{
        "href": "#week-6-links",
        "aria-label": "week 6 links permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <List mdxType="List">
      <Link to="https://www.forbes.com/sites/davidthier/2020/08/13/freefortnite-watch-epic-games-anti-apple-198fortnite-commercial-here/" mdxType="Link">
        <p>{`Epic vs. Apple`}</p>
      </Link>
      <Link to="https://www.theverge.com/2020/8/13/21368363/epic-google-fortnite-lawsuit-antitrust-app-play-store-apple-removal" mdxType="Link">
        <p>{`Epic vs. Google, H/T Luka Sucic`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      